import 'fomantic-ui-dropdown/dropdown'
import 'semantic-ui-transition/transition'

import { Controller } from '@hotwired/stimulus'
import { toggleClassForDropdown } from '@/utils/fields'

$.fn.dropdown.settings.message.addResult = 'Ajouter <b>{term}</b>'
$.fn.dropdown.settings.message.count = '{count} sélectionnés'
$.fn.dropdown.settings.message.maxSelections = '{maxCount} sélections maximum'
$.fn.dropdown.settings.message.noResults = 'Aucun résultat trouvé'

export default class extends Controller {
  static values = {
    config: { type: Object, default: {} }
  }

  connect() {
    this.$element = $(this.element)
    this.$element.dropdown(this.config())
    if (this.$element.hasClass('allow-additions')) {
      this.$element.children('.search').keydown(event => {
        if (event.keyCode === 13) event.preventDefault()
      })
    }
  }

  config() {
    const baseConfig = {
      forceSelection: false,
      fullTextSearch: 'exact',
      ignoreDiacritics: true,
      ignoreSearchCase: true,
      context: 'body',
      match: 'text',
      className: {
        dropdown: 'dropdown',
        label: 'label label-default'
      },
      selector: {
        dropdown: '.dropdown'
      },
      onChange(value, text, $choice) {
        toggleClassForDropdown($(this))
        if ($(this).hasClass('search')) document.activeElement.blur()
      }
    }
    const o = {
      ...baseConfig,
      ...(this.$element.hasClass('dropdown-with-blank') ? { placeholder: false } : {}),
      ...(this.$element.hasClass('allow-additions') ? { allowAdditions: true, hideAdditions: false } : {}),
      ...this.configValue
    }
    return o
  }
}
